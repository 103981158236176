import { compact, isArray, isString } from "lodash";
import useGraph from "../composables/useGraph";
import {
  DerivedPropertyTerm,
  propertyName,
  PropertyOpType,
  propertyValueType,
  underlyingPropertyTypes,
} from "./derived";
import {
  BasePropertyFilter,
  FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE,
  FilterType,
  FilterValue,
  GROUP_BY_ALL,
  PropertyFilter,
} from "./fetchApi";
import { Graph, LinkDescriptor } from "./graph";
import { ConceptKnowledgeRef, PropertyKnowledgeRef } from "./knowledge";
import { GraphValue } from "./value";

// This is the high level "only one way to do it" query interface, built on top
// of the low-level interface available at fetchApi.ts. It's designed to be
// easier to display as a UX and to be written by the LLM

export interface Query {
  root_concept_type: ConceptKnowledgeRef;
  columns: QueryColumn[];
  filters: QueryFilter[];
  order_by: QueryOrderBy[];
  group_by: QueryGroupBy[] | typeof GROUP_BY_ALL;
  size?: number;
}

export interface QueryColumn {
  alias: string;
  property_type: DerivedPropertyTerm;
  path?: QueryPathNode[]; // If no path, this property lives on the root concept.
  displayName?: string;
}

export interface QueryPathNode {
  link_descriptor?: LinkDescriptor;
  concept_type: ConceptKnowledgeRef;
}

export interface QueryFilter<T extends PropertyFilter = PropertyFilter> {
  alias: string;
  path?: QueryPathNode[];
  type: T["type"];
  property_type: PropertyKnowledgeRef;
  values: FilterValue<T>[];
  negated: boolean;
}

export interface QueryGroupBy {
  property_type: DerivedPropertyTerm;
  path?: QueryPathNode[];
}

export interface QueryOrderBy {
  on: string; // a column alias
  asc: boolean;
}

export function filterIsComplete(filter: QueryFilter) {
  return filter.type === FilterType.Exists || filter.values.length > 0;
}

export function emptyQuery(rootConceptType: ConceptKnowledgeRef): Query {
  return {
    root_concept_type: rootConceptType,
    order_by: [],
    group_by: [],
    filters: [],
    columns: [],
  };
}

export function allPathsInQuery(query: Query) {
  return compact([
    ...query.columns.map((c) => c.path),
    ...query.filters.map((f) => f.path),
    ...(isArray(query.group_by) ? query.group_by.map((g) => g.path) : []),
  ]);
}

export function pathsEquivalent(path1: QueryPathNode[], path2: QueryPathNode[]) {
  if (path1.length !== path2.length) return false;
  for (let i = 0; i != path1.length; i++) {
    if (path1[i].concept_type !== path2[i].concept_type) return false;
    if (path1[i].link_descriptor !== path2[i].link_descriptor) return false;
  }
  return true;
}

export function columnName(column: QueryColumn): string {
  return propertyName(column.property_type, column.path, column.displayName);
}

export enum TextFilterMatch {
  Start = "start",
  End = "end",
  Contain = "contain",
  Full = "full",
}

export const textFilterMatchOptions: TextFilterMatch[] = [
  TextFilterMatch.Contain,
  TextFilterMatch.Start,
  TextFilterMatch.End,
  TextFilterMatch.Full,
];

export interface TextFilter extends BasePropertyFilter {
  type: FilterType.Text;
  value: GraphValue;
  match: TextFilterMatch;
  case_sensitive: boolean;
}

// Validates a query against a metagraph, returning a list of reasons why it can't
// be loaded as-is. An empty list means it should be good to go. This list is
// meant to be sent back to an LLM that created the bookmark in the first place
// so it can hopefully fix its mistakes. This could also be used to validate
// a user-created bookmark against schema drift, but the actual strings are not
// worth showing to the user.
//
// This function assumes that the bookmark is *structurally* valid, i.e. matches
// its type, and internally consistent. It returns errors for mismatches between
// the bookmark's references and what's available in the metagraph. If you pass it
// a structurally broken or self-inconsistent bookmark, it will throw runtime
// errors or at least produce invalid results.
export function validateQuery(query: Query, metagraph: Graph) {
  const { getConceptsOfType } = useGraph(() => metagraph);
  // Validate root_concept_type
  const root = query.root_concept_type;
  if (getConceptsOfType(root).length == 0) {
    // Just return - nothing further useful to do
    return [`Root concept type ${query.root_concept_type} not found`];
  }

  const problems: string[] = [];

  // Validate columns
  for (const column of query.columns) {
    if (
      !isString(column.property_type) &&
      !Object.values(PropertyOpType).includes(column.property_type.op)
    ) {
      problems.push(`Column ${column.alias}: invalid op "${column.property_type.op}"`);
      continue; // Won't be able to determine prop types for an invalid op
      // If we teach the LLM how to do nested ops, we'll have to check the entire tree.
    }
    const pts = underlyingPropertyTypes(column.property_type);
    const errs = validatePathReference(metagraph, root, column.path, pts);
    problems.push(...errs.map((e) => `Column ${column.alias}: ${e}`));
  }

  // Validate filters
  for (const filter of query.filters) {
    const refErrs = validatePathReference(metagraph, root, filter.path, [filter.property_type]);
    // No alias included in these error messages because the LLM won't know what they mean
    if (refErrs.length) {
      problems.push(...refErrs.map((e) => `Filter: ${e}`));
      continue; // Unsafe to try to validate filter on a missing property
    }
    problems.push(...validateFilter(filter));
  }

  // Validate group_by
  if (isArray(query.group_by)) {
    for (const gb of query.group_by) {
      const pts = underlyingPropertyTypes(gb.property_type);
      const errs = validatePathReference(metagraph, root, gb.path, pts);
      problems.push(...errs.map((e) => `Group by: ${e}`));
    }
  }

  return compact(problems);
}

function validatePathReference(
  metagraph: Graph,
  rootConceptType: ConceptKnowledgeRef,
  path: QueryPathNode[] = [],
  propertyTypes: PropertyKnowledgeRef[]
): string[] {
  const { getConceptsOfType, getLinkPartners, getConcept } = useGraph(() => metagraph);
  let currentConcept = getConceptsOfType(rootConceptType)[0];
  for (const node of path) {
    if (node.link_descriptor == null) return ["Each path node must have a link_descriptor"];
    const conceptTypeSought = node.concept_type;
    const partner = getLinkPartners(currentConcept.id, node.link_descriptor)
      .map(getConcept)
      .find((partnerConcept) => partnerConcept.type === conceptTypeSought);
    if (partner == null) {
      return [`${currentConcept.type} has no ${node.link_descriptor} link to ${conceptTypeSought}`];
    } else {
      currentConcept = partner;
    }
  }
  // Now that we've found the concept, validate property types
  const problems: string[] = [];
  for (const ptype of propertyTypes) {
    if ((currentConcept.properties || []).find((p) => p.type === ptype) == null) {
      problems.push(`${currentConcept.type} has no property ${ptype}`);
    }
  }
  return problems;
}

function validateFilter(filter: QueryFilter) {
  const problems: string[] = [];
  const propValueType = propertyValueType(filter.property_type);
  const validFilterTypes = FILTER_TYPES_FOR_PROPERTY_VALUE_TYPE[propValueType];
  if (!validFilterTypes.includes(filter.type)) {
    problems.push(
      `Filter on ${filter.property_type} has invalid type ${filter.type} - properties of type ${propValueType} only support filters of types: ${validFilterTypes.join(",")}`
    );
  }
  for (const values of filter.values) {
    for (const value of Object.values(values)) {
      const filterValueType = (value as GraphValue)._type;
      if (filterValueType !== propValueType) {
        problems.push(
          `Filter on ${filter.property_type} has value of type ${filterValueType}, a mismatch with the property's value type (${propValueType})`
        );
      }
    }
  }
  return problems;
}
